import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <script
          src="//tt.mbww.com/tt-1ee4e10c0c7e1bd5ff9cd70d1f3c03521abf21ea7ee45aa92deab16f87af4dbb.js"
          async
        />
        <p id="skip-link">
          <a href="#main-menu" className="element-invisible element-focusable">
            Jump to navigation
          </a>
        </p>
        <div id="mobile-sidebar">
          <div className="mobile-logo-wrapper">
            <a href="/" title="Inicio" rel="home" className="mobile-logo">
              <img
                src="/sites/all/themes/custom/iniston_zen/logo.png"
                alt="Inicio"
              />
            </a>
          </div>
          <div className="region region-mobile-sidebar">
            <div
              id="block-search-form"
              className="block block-search first odd"
              role="search"
            >
              
            </div>
            <div
              id="block-system-main-menu"
              className="block block-system block-menu last even"
              role="navigation"
            >
              <ul className="menu">
                <li className="menu__item is-expanded first expanded">
                  <a href="/la-tos/" className="menu__link">
                    La tos
                  </a>
                  <ul className="menu">
                    <li className="menu__item is-expanded first expanded">
                      <a href="/la-tos/que-es-la-tos/" className="menu__link">
                        ¿Qué es la tos?
                      </a>
                      <ul className="menu">
                        <li className="menu__item is-leaf first leaf">
                          <a
                            href="/la-tos/que-es-la-tos/"
                            className="menu__link"
                          >
                            Definición
                          </a>
                        </li>
                        <li className="menu__item is-leaf leaf">
                          <a
                            href="/la-tos/que-es-la-tos/vivencia/"
                            className="menu__link"
                          >
                            Vivencia
                          </a>
                        </li>
                        <li className="menu__item is-leaf last leaf">
                          <a
                            href="/la-tos/que-es-la-tos/funcionamiento/"
                            className="menu__link"
                          >
                            Funcionamiento
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu__item is-expanded expanded">
                      <a
                        href="/la-tos/tipos-de-tos/seca/"
                        className="menu__link"
                      >
                        Tipos de tos
                      </a>
                      <ul className="menu">
                        <li className="menu__item is-leaf first leaf">
                          <a
                            href="/la-tos/tipos-de-tos/seca/"
                            className="menu__link"
                          >
                            Tos seca
                          </a>
                        </li>
                        <li className="menu__item is-leaf last leaf">
                          <a
                            href="/la-tos/tipos-de-tos/con-mocos/"
                            className="menu__link"
                          >
                            Tos con moco
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu__item is-expanded expanded">
                      <a
                        href="/la-tos/cuando-tienes-tos/con-gripe-resfriado/"
                        className="menu__link"
                      >
                        Momentos de tos
                      </a>
                      <ul className="menu">
                        <li className="menu__item is-leaf first leaf">
                          <a
                            href="/la-tos/cuando-tienes-tos/con-gripe-resfriado/"
                            className="menu__link"
                          >
                            Gripe
                          </a>
                        </li>
                        <li className="menu__item is-leaf leaf">
                          <a
                            href="/la-tos/cuando-tienes-tos/nocturna/"
                            className="menu__link"
                          >
                            Noche
                          </a>
                        </li>
                        <li className="menu__item is-leaf leaf">
                          <a
                            href="/la-tos/cuando-tienes-tos/alergia/"
                            className="menu__link"
                          >
                            Alergia
                          </a>
                        </li>
                        <li className="menu__item is-leaf leaf">
                          <a
                            href="/la-tos/cuando-tienes-tos/verano/"
                            className="menu__link"
                          >
                            Verano
                          </a>
                        </li>
                        <li className="menu__item is-leaf last leaf">
                          <a
                            href="/la-tos/cuando-tienes-tos/fumador/"
                            className="menu__link"
                          >
                            Fumador
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu__item is-leaf last leaf">
                      <a
                        href="/la-tos/como-aliviar-la-tos/"
                        className="menu__link"
                      >
                        Cómo aliviar la tos
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="menu__item is-active-trail is-expanded expanded active-trail">
                  <a
                    href="/remedios/"
                    className="menu__link is-active-trail active-trail"
                  >
                    Remedios contra la tos
                  </a>
                  <ul className="menu">
                    <li className="menu__item is-leaf first leaf">
                      <a
                        href="/remedios/caseros-y-naturales/"
                        className="menu__link"
                      >
                        Remedios caseros
                      </a>
                    </li>
                    <li className="menu__item is-leaf leaf">
                      <a href="/remedios/tos-nocturna/" className="menu__link">
                        Tos nocturna
                      </a>
                    </li>
                    <li className="menu__item is-active-trail is-expanded last expanded active-trail">
                      <a
                        href="/remedios/contra-la-tos/seca/"
                        className="menu__link is-active-trail active-trail"
                      >
                        Recetas contra la tos
                      </a>
                      <ul className="menu">
                        <li className="menu__item is-leaf first leaf">
                          <a
                            href="/remedios/contra-la-tos/seca/"
                            className="menu__link"
                          >
                            Remedios tos seca
                          </a>
                        </li>
                        <li className="menu__item is-active-trail is-leaf last leaf active-trail">
                          <a
                            href="/remedios/contra-la-tos/mocos/"
                            className="menu__link is-active-trail active-trail active"
                          >
                            Remedios tos con moco
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li className="menu__item is-expanded expanded">
                  <a href="/jarabe-iniston/" className="menu__link">
                    Productos
                  </a>
                  <ul className="menu">
                    <li className="menu__item is-expanded first expanded">
                      <a
                        href="/jarabe-iniston/expectorante-descongestivo/"
                        className="menu__link"
                      >
                        Adultos
                      </a>
                      <ul className="menu">
                        <li className="menu__item is-leaf first leaf">
                          <a
                            href="/jarabe-iniston/expectorante-descongestivo/"
                            className="menu__link"
                          >
                            Iniston expectorante y descongestivo
                          </a>
                        </li>
                        <li className="menu__item is-leaf leaf">
                          <a
                            href="/jarabe-iniston/expectorante/"
                            className="menu__link"
                          >
                            Iniston expectorante
                          </a>
                        </li>
                        <li className="menu__item is-leaf leaf">
                          <a
                            href="/jarabe-iniston/antitusivo-descongestivo/"
                            className="menu__link"
                          >
                            Iniston antitusivo y descongestivo
                          </a>
                        </li>
                        <li className="menu__item is-leaf leaf">
                          <a
                            href="/jarabe-iniston/antitusivo/"
                            className="menu__link"
                          >
                            Iniston antitusivo
                          </a>
                        </li>
                        <li className="menu__item is-leaf last leaf">
                          <a
                            href="/jarabe-iniston/mucolitico/"
                            className="menu__link"
                          >
                            Iniston mucolítico
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu__item is-expanded last expanded">
                      <a
                        href="/jarabe-inistolin/pediatrico-expectorante/"
                        className="menu__link"
                      >
                        Niños
                      </a>
                      <ul className="menu">
                        <li className="menu__item is-leaf first leaf">
                          <a
                            href="/jarabe-inistolin/pediatrico-expectorante/"
                            className="menu__link"
                          >
                            Instolin pediátrico expectorante
                          </a>
                        </li>
                        <li className="menu__item is-leaf last leaf">
                          <a
                            href="/jarabe-inistolin/pediatrico-antitusivo/"
                            className="menu__link"
                          >
                            Inistolin pediátrico antitusivo
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li className="menu__item is-expanded last expanded">
                  <a href="/tos-ninos/" className="menu__link">
                    Tos en niños
                  </a>
                  <ul className="menu">
                    <li className="menu__item is-expanded first expanded">
                      <a
                        href="/tos-ninos/tipos-de-tos-ninos/irritativa/"
                        className="menu__link"
                      >
                        Tipos de tos
                      </a>
                      <ul className="menu">
                        <li className="menu__item is-leaf first leaf">
                          <a
                            href="/tos-ninos/tipos-de-tos-ninos/irritativa/"
                            className="menu__link"
                          >
                            Tos seca
                          </a>
                        </li>
                        <li className="menu__item is-leaf last leaf">
                          <a
                            href="/tos-ninos/tipos-de-tos-ninos/productiva-con-flemas/"
                            className="menu__link"
                          >
                            Tos con mocos
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu__item is-expanded expanded">
                      <a
                        href="/tos-ninos/jarabe-inistolin/pediatrico-expectorante/"
                        className="menu__link"
                      >
                        Productos
                      </a>
                      <ul className="menu">
                        <li className="menu__item is-leaf first leaf">
                          <a
                            href="/tos-ninos/jarabe-inistolin/pediatrico-expectorante/"
                            className="menu__link"
                          >
                            Inistolin expectorante
                          </a>
                        </li>
                        <li className="menu__item is-leaf last leaf">
                          <a
                            href="/tos-ninos/jarabe-inistolin/pediatrico-antitusivo/"
                            className="menu__link"
                          >
                            Inistolin antitusivo
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu__item is-leaf leaf">
                      <a href="/tos-ninos/remedios/" className="menu__link">
                        Remedios para la tos en niños
                      </a>
                    </li>
                    <li className="menu__item is-leaf leaf">
                      <a href="/tos-ninos/causas/" className="menu__link">
                        Causas
                      </a>
                    </li>
                    <li className="menu__item is-expanded expanded">
                      <a
                        href="/tos-ninos/desarrollo-infantil/7-anos/"
                        className="menu__link"
                      >
                        Desarrollo infantil
                      </a>
                      <ul className="menu">
                        <li className="menu__item is-leaf first leaf">
                          <a
                            href="/tos-ninos/desarrollo-infantil/7-anos/"
                            className="menu__link"
                          >
                            7 años
                          </a>
                        </li>
                        <li className="menu__item is-leaf leaf">
                          <a
                            href="/tos-ninos/desarrollo-infantil/8-anos/"
                            className="menu__link"
                          >
                            8 años
                          </a>
                        </li>
                        <li className="menu__item is-leaf leaf">
                          <a
                            href="/tos-ninos/desarrollo-infantil/9-anos/"
                            className="menu__link"
                          >
                            9 años
                          </a>
                        </li>
                        <li className="menu__item is-leaf leaf">
                          <a
                            href="/tos-ninos/desarrollo-infantil/10-anos/"
                            className="menu__link"
                          >
                            10 años
                          </a>
                        </li>
                        <li className="menu__item is-leaf leaf">
                          <a
                            href="/tos-ninos/desarrollo-infantil/11-anos/"
                            className="menu__link"
                          >
                            11 años
                          </a>
                        </li>
                        <li className="menu__item is-leaf last leaf">
                          <a
                            href="/tos-ninos/desarrollo-infantil/12-anos/"
                            className="menu__link"
                          >
                            12 años
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu__item is-leaf last leaf">
                      <a href="/dibujos-para-colorear/" className="menu__link">
                        Diviértete con Pandolín
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div id="page">
          <div className="header-wrapper">
            <header className="header" id="header" role="banner">
              <div className="header-top">
                <div className="header-top-left">
                  <span id="mobile-button" />
                </div>
                <a
                  href="/"
                  title="Inicio"
                  rel="home"
                  className="header__logo"
                  id="logo"
                >
                  <img
                    src="/sites/all/themes/custom/iniston_zen/logo.png"
                    alt="Inicio"
                    className="header__logo-image"
                  />
                </a>
                <div className="region region-pre-header">
                  <div
                    id="block-search-form--2"
                    className="block block-search first odd"
                    role="search"
                  >
                    
                  </div>
                  <div
                    id="block-block-3"
                    className="block block-block last even"
                  >
                    <p>
                    <img 
                      alt="" 
                      className="attr__format__media_original img__fid__142 img__view_mode__media_original media-image header-map-pin" 
                      src="/sites/default/files/marker.png"
                    />
                      <strong>Dónde comprar Iniston</strong>
                    </p>
                  </div>
                </div>
              </div>
              <div className="header__region region region-header">
                <div
                  id="block-superfish-1"
                  className="block block-superfish first last odd"
                >
                  <ul
                    id="superfish-1"
                    className="menu sf-menu sf-main-menu sf-horizontal sf-style-none sf-total-items-4 sf-parent-items-4 sf-single-items-0"
                  >
                    <li
                      id="menu-376-1"
                      className="first sf-item-1 sf-depth-1 sf-total-children-4 sf-parent-children-3 sf-single-children-1 menuparent"
                    >
                      <a href="/la-tos/" className="sf-depth-1 menuparent">
                        La tos
                      </a>
                      <ul>
                        <li
                          id="menu-382-1"
                          className="first sf-item-1 sf-depth-2 sf-total-children-3 sf-parent-children-0 sf-single-children-3 menuparent"
                        >
                          <a
                            href="/la-tos/que-es-la-tos/"
                            className="sf-depth-2 menuparent"
                          >
                            ¿Qué es la tos?
                          </a>
                          <ul>
                            <li
                              id="menu-555-1"
                              className="first sf-item-1 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/la-tos/que-es-la-tos/"
                                className="sf-depth-3"
                              >
                                Definición
                              </a>
                            </li>
                            <li
                              id="menu-540-1"
                              className="middle sf-item-2 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/la-tos/que-es-la-tos/vivencia/"
                                className="sf-depth-3"
                              >
                                Vivencia
                              </a>
                            </li>
                            <li
                              id="menu-541-1"
                              className="last sf-item-3 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/la-tos/que-es-la-tos/funcionamiento/"
                                className="sf-depth-3"
                              >
                                Funcionamiento
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li
                          id="menu-460-1"
                          className="middle sf-item-2 sf-depth-2 sf-total-children-2 sf-parent-children-0 sf-single-children-2 menuparent"
                        >
                          <a
                            href="/la-tos/tipos-de-tos/seca/"
                            className="sf-depth-2 menuparent"
                          >
                            Tipos de tos
                          </a>
                          <ul>
                            <li
                              id="menu-461-1"
                              className="first sf-item-1 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/la-tos/tipos-de-tos/seca/"
                                className="sf-depth-3"
                              >
                                Tos seca
                              </a>
                            </li>
                            <li
                              id="menu-462-1"
                              className="last sf-item-2 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/la-tos/tipos-de-tos/con-mocos/"
                                className="sf-depth-3"
                              >
                                Tos con moco
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li
                          id="menu-467-1"
                          className="middle sf-item-3 sf-depth-2 sf-total-children-5 sf-parent-children-0 sf-single-children-5 menuparent"
                        >
                          <a
                            href="/la-tos/cuando-tienes-tos/con-gripe-resfriado/"
                            className="sf-depth-2 menuparent"
                          >
                            Momentos de tos
                          </a>
                          <ul>
                            <li
                              id="menu-470-1"
                              className="first sf-item-1 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/la-tos/cuando-tienes-tos/con-gripe-resfriado/"
                                className="sf-depth-3"
                              >
                                Gripe
                              </a>
                            </li>
                            <li
                              id="menu-472-1"
                              className="middle sf-item-2 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/la-tos/cuando-tienes-tos/nocturna/"
                                className="sf-depth-3"
                              >
                                Noche
                              </a>
                            </li>
                            <li
                              id="menu-474-1"
                              className="middle sf-item-3 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/la-tos/cuando-tienes-tos/alergia/"
                                className="sf-depth-3"
                              >
                                Alergia
                              </a>
                            </li>
                            <li
                              id="menu-476-1"
                              className="middle sf-item-4 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/la-tos/cuando-tienes-tos/verano/"
                                className="sf-depth-3"
                              >
                                Verano
                              </a>
                            </li>
                            <li
                              id="menu-478-1"
                              className="last sf-item-5 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/la-tos/cuando-tienes-tos/fumador/"
                                className="sf-depth-3"
                              >
                                Fumador
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li
                          id="menu-466-1"
                          className="last sf-item-4 sf-depth-2 sf-no-children"
                        >
                          <a
                            href="/la-tos/como-aliviar-la-tos/"
                            className="sf-depth-2"
                          >
                            Cómo aliviar la tos
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li
                      id="menu-377-1"
                      className="active-trail middle sf-item-2 sf-depth-1 sf-total-children-3 sf-parent-children-1 sf-single-children-2 menuparent"
                    >
                      <a href="/remedios/" className="sf-depth-1 menuparent">
                        Remedios contra la tos
                      </a>
                      <ul>
                        <li
                          id="menu-479-1"
                          className="first sf-item-1 sf-depth-2 sf-no-children"
                        >
                          <a
                            href="/remedios/caseros-y-naturales/"
                            className="sf-depth-2"
                          >
                            Remedios caseros
                          </a>
                        </li>
                        <li
                          id="menu-480-1"
                          className="middle sf-item-2 sf-depth-2 sf-no-children"
                        >
                          <a
                            href="/remedios/tos-nocturna/"
                            className="sf-depth-2"
                          >
                            Tos nocturna
                          </a>
                        </li>
                        <li
                          id="menu-454-1"
                          className="active-trail last sf-item-3 sf-depth-2 sf-total-children-2 sf-parent-children-0 sf-single-children-2 menuparent"
                        >
                          <a
                            href="/remedios/contra-la-tos/seca/"
                            className="sf-depth-2 menuparent"
                          >
                            Recetas contra la tos
                          </a>
                          <ul>
                            <li
                              id="menu-458-1"
                              className="first sf-item-1 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/remedios/contra-la-tos/seca/"
                                className="sf-depth-3"
                              >
                                Remedios tos seca
                              </a>
                            </li>
                            <li
                              id="menu-459-1"
                              className="active-trail last sf-item-2 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/remedios/contra-la-tos/mocos/"
                                className="sf-depth-3 active"
                              >
                                Remedios tos con moco
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li
                      id="menu-378-1"
                      className="middle sf-item-3 sf-depth-1 sf-total-children-2 sf-parent-children-2 sf-single-children-0 menuparent"
                    >
                      <a
                        href="/jarabe-iniston/"
                        className="sf-depth-1 menuparent"
                      >
                        Productos
                      </a>
                      <ul>
                        <li
                          id="menu-449-1"
                          className="first sf-item-1 sf-depth-2 sf-total-children-5 sf-parent-children-0 sf-single-children-5 menuparent"
                        >
                          <a
                            href="/jarabe-iniston/expectorante-descongestivo/"
                            className="sf-depth-2 menuparent"
                          >
                            Adultos
                          </a>
                          <ul>
                            <li
                              id="menu-447-1"
                              className="first sf-item-1 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/jarabe-iniston/expectorante-descongestivo/"
                                className="sf-depth-3"
                              >
                                Iniston expectorante y descongestivo
                              </a>
                            </li>
                            <li
                              id="menu-446-1"
                              className="middle sf-item-2 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/jarabe-iniston/expectorante/"
                                className="sf-depth-3"
                              >
                                Iniston expectorante
                              </a>
                            </li>
                            <li
                              id="menu-445-1"
                              className="middle sf-item-3 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/jarabe-iniston/antitusivo-descongestivo/"
                                className="sf-depth-3"
                              >
                                Iniston antitusivo y descongestivo
                              </a>
                            </li>
                            <li
                              id="menu-444-1"
                              className="middle sf-item-4 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/jarabe-iniston/antitusivo/"
                                className="sf-depth-3"
                              >
                                Iniston antitusivo
                              </a>
                            </li>
                            <li
                              id="menu-443-1"
                              className="last sf-item-5 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/jarabe-iniston/mucolitico/"
                                className="sf-depth-3"
                              >
                                Iniston mucolítico
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li
                          id="menu-451-1"
                          className="last sf-item-2 sf-depth-2 sf-total-children-2 sf-parent-children-0 sf-single-children-2 menuparent"
                        >
                          <a
                            href="/jarabe-inistolin/pediatrico-expectorante/"
                            className="sf-depth-2 menuparent"
                          >
                            Niños
                          </a>
                          <ul>
                            <li
                              id="menu-442-1"
                              className="first sf-item-1 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/jarabe-inistolin/pediatrico-expectorante/"
                                className="sf-depth-3"
                              >
                                Instolin pediátrico expectorante
                              </a>
                            </li>
                            <li
                              id="menu-441-1"
                              className="last sf-item-2 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/jarabe-inistolin/pediatrico-antitusivo/"
                                className="sf-depth-3"
                              >
                                Inistolin pediátrico antitusivo
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li
                      id="menu-379-1"
                      className="last sf-item-4 sf-depth-1 sf-total-children-6 sf-parent-children-3 sf-single-children-3 menuparent"
                    >
                      <a href="/tos-ninos/" className="sf-depth-1 menuparent">
                        Tos en niños
                      </a>
                      <ul>
                        <li
                          id="menu-482-1"
                          className="first sf-item-1 sf-depth-2 sf-total-children-2 sf-parent-children-0 sf-single-children-2 menuparent"
                        >
                          <a
                            href="/tos-ninos/tipos-de-tos-ninos/irritativa/"
                            className="sf-depth-2 menuparent"
                          >
                            Tipos de tos
                          </a>
                          <ul>
                            <li
                              id="menu-485-1"
                              className="first sf-item-1 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/tos-ninos/tipos-de-tos-ninos/irritativa/"
                                className="sf-depth-3"
                              >
                                Tos seca
                              </a>
                            </li>
                            <li
                              id="menu-484-1"
                              className="last sf-item-2 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/tos-ninos/tipos-de-tos-ninos/productiva-con-flemas/"
                                className="sf-depth-3"
                              >
                                Tos con mocos
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li
                          id="menu-546-1"
                          className="middle sf-item-2 sf-depth-2 sf-total-children-2 sf-parent-children-0 sf-single-children-2 menuparent"
                        >
                          <a
                            href="/tos-ninos/jarabe-inistolin/pediatrico-expectorante/"
                            className="sf-depth-2 menuparent"
                          >
                            Productos
                          </a>
                          <ul>
                            <li
                              id="menu-553-1"
                              className="first sf-item-1 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/tos-ninos/jarabe-inistolin/pediatrico-expectorante/"
                                className="sf-depth-3"
                              >
                                Inistolin expectorante
                              </a>
                            </li>
                            <li
                              id="menu-554-1"
                              className="last sf-item-2 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/tos-ninos/jarabe-inistolin/pediatrico-antitusivo/"
                                className="sf-depth-3"
                              >
                                Inistolin antitusivo
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li
                          id="menu-537-1"
                          className="middle sf-item-3 sf-depth-2 sf-no-children"
                        >
                          <a href="/tos-ninos/remedios/" className="sf-depth-2">
                            Remedios para la tos en niños
                          </a>
                        </li>
                        <li
                          id="menu-487-1"
                          className="middle sf-item-4 sf-depth-2 sf-no-children"
                        >
                          <a href="/tos-ninos/causas/" className="sf-depth-2">
                            Causas
                          </a>
                        </li>
                        <li
                          id="menu-488-1"
                          className="middle sf-item-5 sf-depth-2 sf-total-children-6 sf-parent-children-0 sf-single-children-6 menuparent"
                        >
                          <a
                            href="/tos-ninos/desarrollo-infantil/7-anos/"
                            className="sf-depth-2 menuparent"
                          >
                            Desarrollo infantil
                          </a>
                          <ul>
                            <li
                              id="menu-501-1"
                              className="first sf-item-1 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/tos-ninos/desarrollo-infantil/7-anos/"
                                className="sf-depth-3"
                              >
                                7 años
                              </a>
                            </li>
                            <li
                              id="menu-498-1"
                              className="middle sf-item-2 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/tos-ninos/desarrollo-infantil/8-anos/"
                                className="sf-depth-3"
                              >
                                8 años
                              </a>
                            </li>
                            <li
                              id="menu-497-1"
                              className="middle sf-item-3 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/tos-ninos/desarrollo-infantil/9-anos/"
                                className="sf-depth-3"
                              >
                                9 años
                              </a>
                            </li>
                            <li
                              id="menu-495-1"
                              className="middle sf-item-4 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/tos-ninos/desarrollo-infantil/10-anos/"
                                className="sf-depth-3"
                              >
                                10 años
                              </a>
                            </li>
                            <li
                              id="menu-493-1"
                              className="middle sf-item-5 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/tos-ninos/desarrollo-infantil/11-anos/"
                                className="sf-depth-3"
                              >
                                11 años
                              </a>
                            </li>
                            <li
                              id="menu-490-1"
                              className="last sf-item-6 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/tos-ninos/desarrollo-infantil/12-anos/"
                                className="sf-depth-3"
                              >
                                12 años
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li
                          id="menu-439-1"
                          className="last sf-item-6 sf-depth-2 sf-no-children"
                        >
                          <a
                            href="/dibujos-para-colorear/"
                            className="sf-depth-2"
                          >
                            Diviértete con Pandolín
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </header>
          </div>
          <div id="main">
            <div id="content" className="column" role="main">
              <nav className="breadcrumb" role="navigation">
                <h2 className="element-invisible">Se encuentra usted aquí</h2>
                <ol>
                  <li>
                    <a href="/">Inicio</a> |{" "}
                  </li>
                  <li>
                    <a href="/remedios/">Remedios contra la tos</a> |{" "}
                  </li>
                  <li>
                    <a href="/remedios/contra-la-tos/seca/">
                      Recetas contra la tos
                    </a>{" "}
                    |{" "}
                  </li>
                  <li>Recetas para la tos</li>
                </ol>
              </nav>{" "}
              <a id="main-content" />
              <h1 className="page__title title" id="page-title">
                <span>Recetas para la tos</span>
              </h1>
              <div className="header-text">
                <div className="content">
                  <p>
                    Muchos alimentos fáciles de encontrar constituyen una fuente
                    de vitaminas que puede ayudarnos a prevenir resfriados y a
                    curar la tos. Por eso, incorporarlos a nuestra dieta es una
                    forma de mejorar nuestra salud.
                  </p>
                </div>
              </div>
              <div className="region region-highlighted">
                <div
                  id="block-menu-menu-recipes"
                  className="block block-menu first last odd"
                  role="navigation"
                >
                  <ul className="menu">
                    <li className="menu__item is-leaf first leaf">
                      <a
                        href="/remedios/contra-la-tos/seca/"
                        className="menu__link"
                      >
                        Remedios tos seca
                      </a>
                    </li>
                    <li className="menu__item is-active-trail is-leaf last leaf active-trail">
                      <a
                        href="/remedios/contra-la-tos/mocos/"
                        className="menu__link is-active-trail active-trail active"
                      >
                        Remedios tos con moco
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <article className="node-13 node node-remedy view-mode-full clearfix">
                <header></header>
                <div className="node-content-wrapper">
                  <div className="node-content">
                    <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                      <div className="field-items">
                        <div className="field-item even">
                          <h1>REMEDIOS PARA LA TOS CON MOCOS</h1>
                        </div>
                      </div>
                    </div>
                    <div className="field-collection-container clearfix">
                      <div className="field field-name-field-recipes-section field-type-field-collection field-label-hidden">
                        <div className="field-items">
                          <div className="field-item even">
                            <div className="field-collection-view clearfix view-mode-full">
                              <div className="entity entity-field-collection-item field-collection-item-field-recipes-section clearfix">
                                <div className="content">
                                  <div className="field field-name-field-fc-recipes-section-title field-type-text field-label-hidden">
                                    <div className="field-items">
                                      <div className="field-item even">
                                        Prevención bronquitis
                                      </div>
                                    </div>
                                  </div>
                                  <div className="field field-name-field-fc-recipes-section-body field-type-text-long field-label-hidden">
                                    <div className="field-items">
                                      <div className="field-item even">
                                        <p>
                                          <em>
                                            <strong>
                                              Una de las principales causas que
                                              provocan la tos expectorante es la
                                              sobreinfección vírica o bacteriana
                                              que es producida por tener unas
                                              defensas bajas.
                                            </strong>
                                          </em>
                                        </p>
                                        <p>
                                          Por ello, debemos potenciar el consumo
                                          de
                                          <em>
                                            <strong>
                                              {" "}
                                              alimentos que estimulen nuestra
                                              respuesta inmune para prevenir la
                                              tos con mocos en futuros cuadros
                                            </strong>
                                          </em>
                                          .
                                        </p>
                                        <p>
                                          Uno de los nutrientes encargados de
                                          estimular la respuesta inmune es la
                                          vitamina E, presente en los siguientes
                                          alimentos:
                                        </p>
                                        <ul className="list-triangle">
                                          <li>Vegetales de hoja verde.</li>
                                          <li>Frutos secos.</li>
                                          <li>Aceite de oliva.</li>
                                        </ul>
                                        <p>
                                          <em>
                                            <strong>
                                              Te ofrecemos remedios para la tos
                                              con mocos con altos contenidos en
                                              vitamina E para aumentar tus
                                              defensas y así no tener tos con
                                              mocos.
                                            </strong>
                                          </em>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="field field-name-field-fc-recipes-section-recipe field-type-entityreference field-label-hidden">
                                    <div className="field-items">
                                      <div className="field-item even">
                                        <article className="node-10 node node-recipe node-teaser toggle-teaser clearfix">
                                          <header></header>
                                          <div className="right">
                                            <div className="field field-name-field-recipe-img field-type-image field-label-hidden">
                                              <div className="field-items">
                                                <div className="field-item even">
                                                  <img
                                                    src="/sites/default/files/recipe/ensalada-de-espinacas.png"
                                                    width="545"
                                                    height="430"
                                                    alt="Ensalada de espinacas con fresas, nueces y pechuga de pollo"
                                                    title="Ensalada de espinacas con fresas, nueces y pechuga de pollo"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="left">
                                            <div className="recipe-wrapper">
                                              <div className="recipe-title">
                                                <h3>
                                                  Ensalada de espinacas con
                                                  fresas, nueces y pechuga de
                                                  pollo
                                                </h3>
                                                <button className="show-recipe btn btn-blue">
                                                  Ver receta
                                                </button>
                                              </div>
                                              <div className="content">
                                                <div className="field field-name-body field-type-text-with-summary field-label-above">
                                                  <div className="field-label">
                                                    Ingredientes:&nbsp;
                                                  </div>
                                                  <div className="field-items">
                                                    <div className="field-item even">
                                                      <p>
                                                        Espinacas, fresas,
                                                        nueces, pasas, tomate
                                                        cherry, 15 g de queso
                                                        parmesano, 60 g de
                                                        pechuga de pollo
                                                        hervida, 10 ml de aceite
                                                        de oliva
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="field field-name-field-recipe-steps field-type-text-long field-label-hidden">
                                                  <div className="field-items">
                                                    <div className="field-item even">
                                                      <ol className="list-numbers">
                                                        <li>
                                                          Utilizamos la hoja de
                                                          espinaca en crudo.
                                                        </li>
                                                        <li>
                                                          Fileteamos las fresas
                                                          y añadimos el resto de
                                                          ingredientes.
                                                        </li>
                                                        <li>
                                                          Por último aliñamos
                                                          con un poco de zumo de
                                                          limón y 10ml de aceite
                                                          de oliva.
                                                        </li>
                                                      </ol>
                                                    </div>
                                                  </div>
                                                </div>{" "}
                                              </div>
                                            </div>
                                          </div>
                                        </article>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="field-item odd">
                            <div className="field-collection-view clearfix view-mode-full">
                              <div className="entity entity-field-collection-item field-collection-item-field-recipes-section clearfix">
                                <div className="content">
                                  <div className="field field-name-field-fc-recipes-section-title field-type-text field-label-hidden">
                                    <div className="field-items">
                                      <div className="field-item even">
                                        Disminuir la producción de moco
                                      </div>
                                    </div>
                                  </div>
                                  <div className="field field-name-field-fc-recipes-section-body field-type-text-long field-label-hidden">
                                    <div className="field-items">
                                      <div className="field-item even">
                                        <p>
                                          <em>
                                            <strong>
                                              Es muy importante saber que se
                                              debe reducir el consumo de lácteos
                                              ya que estos productos influyen en
                                              la producción de moco.
                                            </strong>
                                          </em>
                                        </p>
                                        <p>
                                          Para evitar problemas derivados de su
                                          falta de consumo, hay que potenciar
                                          alimentos ricos en calcio.
                                        </p>
                                        <ul className="list-triangle">
                                          <li>Verduras de hoja verde.</li>
                                          <li>
                                            Cereales integrales: avena, arroz.
                                            cebada, trigo.
                                          </li>
                                          <li>
                                            Frutos secos: almendras, avellanas,
                                            castañas, higos.
                                          </li>
                                          <li>
                                            Leches vegetales: arroz, avena,
                                            sésamo.
                                          </li>
                                        </ul>
                                        <p>
                                          <em>
                                            <strong>
                                              Te oferemos un remedio contra la
                                              tos con mocos rico en calcio.
                                            </strong>
                                          </em>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="field field-name-field-fc-recipes-section-recipe field-type-entityreference field-label-hidden">
                                    <div className="field-items">
                                      <div className="field-item even">
                                        <article className="node-11 node node-recipe node-teaser toggle-teaser clearfix">
                                          <header></header>
                                          <div className="right">
                                            <div className="field field-name-field-recipe-img field-type-image field-label-hidden">
                                              <div className="field-items">
                                                <div className="field-item even">
                                                  <img
                                                    src="/sites/default/files/recipe/arroz-con-leche.png"
                                                    width="455"
                                                    height="307"
                                                    alt="Arroz con leche de avena y avellanas"
                                                    title="Arroz con leche de avena y avellanas"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="left">
                                            <div className="recipe-wrapper">
                                              <div className="recipe-title">
                                                <h3>
                                                  Arroz con leche de avena y
                                                  avellanas
                                                </h3>
                                                <button className="show-recipe btn btn-blue">
                                                  Ver receta
                                                </button>
                                              </div>
                                              <div className="content">
                                                <div className="field field-name-body field-type-text-with-summary field-label-above">
                                                  <div className="field-label">
                                                    Ingredientes:&nbsp;
                                                  </div>
                                                  <div className="field-items">
                                                    <div className="field-item even">
                                                      <p>
                                                        2 vasos de bebida de
                                                        soja, 15g de arroz, 1
                                                        cucharada de postre de
                                                        azúcar, cáscara de medio
                                                        limón, 1 palo de canela,
                                                        canela mólida y 1 puñado
                                                        de avellanas.
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="field field-name-field-recipe-steps field-type-text-long field-label-hidden">
                                                  <div className="field-items">
                                                    <div className="field-item even">
                                                      <ol className="list-numbers">
                                                        <li>
                                                          Mezclamos en una
                                                          cazuela la leche de
                                                          soja con las avellanas
                                                          (las troceamos
                                                          previamente), la piel
                                                          del medio limón, el
                                                          palo de canela y el
                                                          arroz.
                                                        </li>
                                                        <li>
                                                          Removemos con una
                                                          espátula y dejamos que
                                                          se cocina a fuego
                                                          suave durante
                                                          45&nbsp;minutos. Vamos
                                                          añadiendo el azúcar y
                                                          algo más de bebida de
                                                          soja durante la
                                                          cocción hasta que la
                                                          mezcla quede cremosa.
                                                        </li>
                                                        <li>
                                                          Servimos en botes
                                                          individuales.
                                                        </li>
                                                      </ol>
                                                    </div>
                                                  </div>
                                                </div>{" "}
                                              </div>
                                            </div>
                                          </div>
                                        </article>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="field-item even">
                            <div className="field-collection-view clearfix view-mode-full">
                              <div className="entity entity-field-collection-item field-collection-item-field-recipes-section clearfix">
                                <div className="content">
                                  <div className="field field-name-field-fc-recipes-section-title field-type-text field-label-hidden">
                                    <div className="field-items">
                                      <div className="field-item even">
                                        Eliminación de la mucosidad
                                      </div>
                                    </div>
                                  </div>
                                  <div className="field field-name-field-fc-recipes-section-body field-type-text-long field-label-hidden">
                                    <div className="field-items">
                                      <div className="field-item even">
                                        <p>
                                          <strong>
                                            Alimentos como el ajo, la cebolla o
                                            los puerros, están especialmente
                                            indicados como remedio para el
                                            exceso de mocos, ya que poseen
                                            efectos expectorantes y
                                            mucolíticos.&nbsp;
                                          </strong>
                                        </p>
                                        <p>
                                          Tienen la propiedad de destruir o
                                          disolver la mucina (glucoproteína
                                          principal del moco), gracias a su
                                          composición en compuestos azufrados.
                                        </p>
                                        <ul className="list-triangle">
                                          <li>
                                            <p>Cremas&nbsp;</p>
                                          </li>
                                          <li>
                                            <p>Sopas</p>
                                          </li>
                                        </ul>
                                        <p>
                                          <strong>
                                            Te ofrecemos una propuesta de crema
                                            para remediar la tos con mocos.
                                          </strong>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="field field-name-field-fc-recipes-section-recipe field-type-entityreference field-label-hidden">
                                    <div className="field-items">
                                      <div className="field-item even">
                                        <article className="node-38 node node-recipe node-teaser toggle-teaser clearfix">
                                          <header></header>
                                          <div className="right">
                                            <div className="field field-name-field-recipe-img field-type-image field-label-hidden">
                                              <div className="field-items">
                                                <div className="field-item even">
                                                  <img
                                                    src="/sites/default/files/recipe/crema-de-puerros.png"
                                                    width="463"
                                                    height="333"
                                                    alt="Crema de puerros"
                                                    title="Crema de puerros"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="left">
                                            <div className="recipe-wrapper">
                                              <div className="recipe-title">
                                                <h3>Crema de puerros</h3>
                                                <button className="show-recipe btn btn-blue">
                                                  Ver receta
                                                </button>
                                              </div>
                                              <div className="content">
                                                <div className="field field-name-body field-type-text-with-summary field-label-above">
                                                  <div className="field-label">
                                                    Ingredientes:&nbsp;
                                                  </div>
                                                  <div className="field-items">
                                                    <div className="field-item even">
                                                      <p>
                                                        125 g de puerros, 100 g
                                                        de patata, 50 g de
                                                        zanahoria, 1/2
                                                        cebolleta, 2 dientes de
                                                        ajo, 30 ml de aceite de
                                                        oliva y una pizca de
                                                        sal.
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="field field-name-field-recipe-steps field-type-text-long field-label-hidden">
                                                  <div className="field-items">
                                                    <div className="field-item even">
                                                      <ol className="list-numbers">
                                                        <li>
                                                          <p>
                                                            Introducimos el
                                                            aceite en una
                                                            cazuela y salteamos
                                                            la cebolleta.
                                                            Añadimos la patata
                                                            troceada junto a la
                                                            zanahoria y lo
                                                            cubrimos de agua.
                                                          </p>
                                                        </li>
                                                        <li>
                                                          <p>
                                                            Una vez empieza a
                                                            hervir, añadimos los
                                                            puerros limpios y
                                                            troceados y una
                                                            pizca de sal.
                                                            Tapamos la cazuela y
                                                            dejamos hervir a
                                                            fuego suave durante
                                                            40 minutos.
                                                          </p>
                                                        </li>
                                                        <li>
                                                          <p>
                                                            A continuación le
                                                            añadimos los ajos
                                                            previamente
                                                            laminados y
                                                            salteados con
                                                            aceite.
                                                          </p>
                                                        </li>
                                                        <li>
                                                          <p>
                                                            Una vez esté todo
                                                            cocido, pasamos por
                                                            el pasapurés o
                                                            batidora hasta
                                                            conseguir una crema
                                                            con una textura
                                                            suave. Si queda muy
                                                            espesa, añadimos
                                                            agua caliente.
                                                          </p>
                                                        </li>
                                                      </ol>
                                                    </div>
                                                  </div>
                                                </div>{" "}
                                              </div>
                                            </div>
                                          </div>
                                        </article>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="field-item odd">
                            <div className="field-collection-view clearfix view-mode-full">
                              <div className="entity entity-field-collection-item field-collection-item-field-recipes-section clearfix">
                                <div className="content">
                                  <div className="field field-name-field-fc-recipes-section-title field-type-text field-label-hidden">
                                    <div className="field-items">
                                      <div className="field-item even">
                                        Congestión
                                      </div>
                                    </div>
                                  </div>
                                  <div className="field field-name-field-fc-recipes-section-body field-type-text-long field-label-hidden">
                                    <div className="field-items">
                                      <div className="field-item even">
                                        <p>
                                          <em>
                                            <strong>
                                              ALIVIA LA TOS CON CONGESTION NASAL
                                            </strong>
                                          </em>
                                        </p>
                                        <p>
                                          En muchas ocasiones la tos viene
                                          acompañada de congestión, sobre todo
                                          en los cambios de estación. La
                                          congestión se produce cuando se
                                          inflaman las membranas que recubren la
                                          nariz a causa de la dilatación de los
                                          vasos sanguíneos.
                                        </p>
                                        <p>
                                          Se ha demostrado que la vitamina A es
                                          fundamental en los procesos
                                          infecciosos y sobre todo para la
                                          integridad de la superficie de las
                                          mucosas.{" "}
                                          <strong>
                                            Como remedio para la tos y la
                                            congestión, podemos ingerir
                                            alimentos como el huevo
                                          </strong>
                                          , que son fuente de esta vitamina,
                                          pero si lo combinamos con el calabacín
                                          resulta una comida interesante ya que
                                          el calabacín es rico en mucílagos y
                                          tiene una acción emoliente
                                          (suavizante) a nivel de mucosas.
                                        </p>
                                        <p>
                                          También se ha demostrado que alimentos
                                          como la zanahoria o el bonito{" "}
                                          <strong>
                                            son ricos en vitamina A y alivian la
                                            tos y la congestión.
                                          </strong>
                                          <br />
                                          A continuación te mostramos una lista
                                          de alimentos ricos
                                          <br />
                                          en vitamina A.
                                        </p>
                                        <ul className="list-triangle">
                                          <li>Brócoli.</li>
                                          <li>Batata.</li>
                                          <li>Espinacas.</li>
                                          <li>Calabaza.</li>
                                          <li>Melón.</li>
                                          <li>Mango.</li>
                                          <li>Pez espada.</li>
                                        </ul>
                                        <p>
                                          <em>
                                            <strong>
                                              Te ofrecemos recetas para aliviar
                                              la tos acompañada de congestión.
                                            </strong>
                                          </em>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="field field-name-field-fc-recipes-section-recipe field-type-entityreference field-label-hidden">
                                    <div className="field-items">
                                      <div className="field-item even">
                                        <article className="node-39 node node-recipe node-teaser toggle-teaser clearfix">
                                          <header></header>
                                          <div className="right">
                                            <div className="field field-name-field-recipe-img field-type-image field-label-hidden">
                                              <div className="field-items">
                                                <div className="field-item even">
                                                  <img
                                                    src="/sites/default/files/recipe/tortilla-de-calabacin.png"
                                                    width="463"
                                                    height="338"
                                                    alt="Tortilla de calabacín"
                                                    title="Tortilla de calabacín"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="left">
                                            <div className="recipe-wrapper">
                                              <div className="recipe-title">
                                                <h3>Tortilla de calabacín</h3>
                                                <button className="show-recipe btn btn-blue">
                                                  Ver receta
                                                </button>
                                              </div>
                                              <div className="content">
                                                <div className="field field-name-body field-type-text-with-summary field-label-above">
                                                  <div className="field-label">
                                                    Ingredientes:&nbsp;
                                                  </div>
                                                  <div className="field-items">
                                                    <div className="field-item even">
                                                      <p>
                                                        2 huevos, 100 g de
                                                        calabacín y 10 ml de
                                                        aceite de oliva
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="field field-name-field-recipe-steps field-type-text-long field-label-hidden">
                                                  <div className="field-items">
                                                    <div className="field-item even">
                                                      <ol className="list-numbers">
                                                        <li>
                                                          <p>
                                                            Troceamos en dados
                                                            el calabacín y lo
                                                            salteamos a fuego
                                                            lento.
                                                          </p>
                                                        </li>
                                                        <li>
                                                          <p>
                                                            Batimos los huevos y
                                                            les añadimos una
                                                            pizca de sal.
                                                          </p>
                                                        </li>
                                                        <li>
                                                          <p>
                                                            A continuación
                                                            introducimos los
                                                            huevos batidos en la
                                                            sartén. Dejamos que
                                                            se cuaje a fuego
                                                            lento y le damos la
                                                            vuelta. Dejamos de
                                                            nuevo que se vuelva
                                                            a cuajar por el otro
                                                            lado y la retiramos.
                                                          </p>
                                                        </li>
                                                      </ol>
                                                    </div>
                                                  </div>
                                                </div>{" "}
                                              </div>
                                            </div>
                                          </div>
                                        </article>
                                      </div>
                                      <div className="field-item odd">
                                        <article className="node-40 node node-recipe node-teaser toggle-teaser clearfix">
                                          <header></header>
                                          <div className="right">
                                            <div className="field field-name-field-recipe-img field-type-image field-label-hidden">
                                              <div className="field-items">
                                                <div className="field-item even">
                                                  <img
                                                    src="/sites/default/files/recipe/pure-de-zanahoria-y-bonito.png"
                                                    width="394"
                                                    height="241"
                                                    alt="Puré de zanahoria y bonito"
                                                    title="Puré de zanahoria y bonito"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="left">
                                            <div className="recipe-wrapper">
                                              <div className="recipe-title">
                                                <h3>
                                                  Puré de zanahoria y bonito
                                                </h3>
                                                <button className="show-recipe btn btn-blue">
                                                  Ver receta
                                                </button>
                                              </div>
                                              <div className="content">
                                                <div className="field field-name-body field-type-text-with-summary field-label-above">
                                                  <div className="field-label">
                                                    Ingredientes:&nbsp;
                                                  </div>
                                                  <div className="field-items">
                                                    <div className="field-item even">
                                                      <p>
                                                        1 bonito mediano, 2
                                                        zanahorias y una pizca
                                                        de sal
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="field field-name-field-recipe-steps field-type-text-long field-label-hidden">
                                                  <div className="field-items">
                                                    <div className="field-item even">
                                                      <ol className="list-numbers">
                                                        <li>
                                                          <p>
                                                            Pelar y cortar en
                                                            dados el bonito y la
                                                            zanahoria.
                                                          </p>
                                                        </li>
                                                        <li>
                                                          <p>
                                                            Colocamos los dados
                                                            en un cazo y
                                                            cubrimos con agua.
                                                            Cocemos durante 15
                                                            minutos.
                                                          </p>
                                                        </li>
                                                        <li>
                                                          <p>
                                                            Escurrimos bien y
                                                            guardamos un poco de
                                                            líquido de cocción.
                                                          </p>
                                                        </li>
                                                        <li>
                                                          <p>
                                                            Ponemos las verduras
                                                            cocidas en la
                                                            batidora y las
                                                            batimos añadiendo
                                                            poco a poco el
                                                            líquido de cocción
                                                            hasta conseguir la
                                                            consistencia
                                                            deseada.
                                                          </p>
                                                        </li>
                                                        <li>
                                                          <p>
                                                            Añadimos una pizca
                                                            de sal.
                                                          </p>
                                                        </li>
                                                      </ol>
                                                    </div>
                                                  </div>
                                                </div>{" "}
                                              </div>
                                            </div>
                                          </div>
                                        </article>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="field-item even">
                            <div className="field-collection-view clearfix view-mode-full field-collection-view-final">
                              <div className="entity entity-field-collection-item field-collection-item-field-recipes-section clearfix">
                                <div className="content">
                                  <div className="field field-name-field-fc-recipes-section-body field-type-text-long field-label-hidden">
                                    <div className="field-items">
                                      <div className="field-item even">
                                        <p>
                                          <em>
                                            <strong>
                                              ELIMINA LA MUCOSIDAD
                                            </strong>
                                          </em>
                                        </p>
                                        <p>
                                          Alimentos como el ajo, la cebolla o
                                          los puerros, están especialmente
                                          indicados en caso de exceso de mocos,
                                          ya que poseen efectos expectorantes y
                                          mucolíticos. Tienen la propiedad de
                                          destruir o disolver la mucina
                                          (glucoproteína principal del moco)
                                          gracias a su composición en compuestos
                                          azufrados.
                                        </p>
                                        <p>
                                          Es por ello que consumir cremas o
                                          sopas puede favorecer la mejora de la
                                          sintomatología.
                                        </p>
                                        <p>
                                          En especial la cebolla nos ayuda a
                                          combatir la mucosidad. Inhalar su
                                          esencia facilita la eliminación del
                                          moco y además actúa de
                                          bacteriostático. Al ingerirla, a los
                                          pocos minutos su esencia se elimina
                                          por las vías respiratorias y resulta
                                          idóneo para{" "}
                                          <strong>
                                            combatir infecciones respiratorias y
                                            aliviar la tos y la congestión.
                                          </strong>
                                        </p>
                                        <p>
                                          <em>
                                            <strong>
                                              Te ofrecemos esta receta para
                                              aliviar la tos con muscosidad.
                                            </strong>
                                          </em>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="field field-name-field-fc-recipes-section-recipe field-type-entityreference field-label-hidden">
                                    <div className="field-items">
                                      <div className="field-item even">
                                        <article className="node-41 node node-recipe node-teaser toggle-teaser clearfix">
                                          <header></header>
                                          <div className="right">
                                            <div className="field field-name-field-recipe-img field-type-image field-label-hidden">
                                              <div className="field-items">
                                                <div className="field-item even">
                                                  <img
                                                    src="/sites/default/files/recipe/sopa-de-cebolla.png"
                                                    width="349"
                                                    height="277"
                                                    alt="Sopa de cebolla"
                                                    title="Sopa de cebolla"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="left">
                                            <div className="recipe-wrapper">
                                              <div className="recipe-title">
                                                <h3>Sopa de cebolla</h3>
                                                <button className="show-recipe btn btn-blue">
                                                  Ver receta
                                                </button>
                                              </div>
                                              <div className="content">
                                                <div className="field field-name-body field-type-text-with-summary field-label-above">
                                                  <div className="field-label">
                                                    Ingredientes:&nbsp;
                                                  </div>
                                                  <div className="field-items">
                                                    <div className="field-item even">
                                                      <p>
                                                        125 g de cebolla, 15 g
                                                        de mantequilla, 10 ml de
                                                        aceite de oliva, una
                                                        pizca de harina, 10 ml
                                                        de vino blanco, 1 vaso
                                                        de caldo de carne, 60 g
                                                        de pan, 10 g de queso
                                                        parmesano y una pizca de
                                                        sal.
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="field field-name-field-recipe-steps field-type-text-long field-label-hidden">
                                                  <div className="field-items">
                                                    <div className="field-item even">
                                                      <ol className="list-numbers">
                                                        <li>
                                                          <p>
                                                            Cortamos la cebolla
                                                            en rodajas.
                                                          </p>
                                                        </li>
                                                        <li>
                                                          <p>
                                                            Calentamos en una
                                                            sartén la
                                                            mantequilla y el
                                                            aceite.
                                                          </p>
                                                        </li>
                                                        <li>
                                                          <p>
                                                            Añadimos la cebolla
                                                            hasta que se dore y
                                                            añadimos la harina y
                                                            el vino (2 minutos
                                                            aprox).
                                                          </p>
                                                        </li>
                                                        <li>
                                                          <p>
                                                            Añadimos el caldo de
                                                            carne y dejamos que
                                                            se cocine unos 2
                                                            minutos.
                                                          </p>
                                                        </li>
                                                        <li>
                                                          <p>
                                                            Añadimos el queso y
                                                            rectificamos con
                                                            sal.
                                                          </p>
                                                        </li>
                                                        <li>
                                                          <p>
                                                            Precalentamos el
                                                            horno a 200ºC.
                                                            Añadimos un poco de
                                                            queso por encima y
                                                            gratinamos.
                                                          </p>
                                                        </li>
                                                        <li>
                                                          <p>
                                                            Acompañamos con el
                                                            pan tostado.
                                                          </p>
                                                        </li>
                                                      </ol>
                                                    </div>
                                                  </div>
                                                </div>{" "}
                                              </div>
                                            </div>
                                          </div>
                                        </article>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="field field-name-field-highlight-ref field-type-entityreference field-label-hidden">
                      <div className="field-items">
                        <div className="field-item even">
                          <article className="node-87 node node-highlight node-teaser clearfix">
                            <header>
                              <h2 className="node__title node-title">
                                <a href="/jarabe-iniston/expectorante-descongestivo/">
                                  Alivio de los mocos y la congestión
                                </a>
                              </h2>
                            </header>
                            <div className="node-content-wrapper">
                              <div className="node-content">
                                <div className="group-highlight-left field-group-div">
                                  <div className="field field-name-field-highlight-img field-type-image field-label-hidden">
                                    <div className="field-items">
                                      <div className="field-item even">
                                        <img
                                          src="/sites/default/files/styles/highlight/public/highlight/iniston-expectorante-anticongestivo.png?itok=ngEZHp3s"
                                          width="302"
                                          height="157"
                                          alt="Alivio de los mocos y la congestión"
                                          title="Alivio de los mocos y la congestión"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="group-highlight-right field-group-div">
                                  <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                                    <div className="field-items">
                                      <div className="field-item even">
                                        <p>
                                          Su doble acción actúa acabando con la
                                          tos con mocos y la congestión nasal en
                                          procesos catarrales.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="field field-name-field-highlight-link field-type-link-field field-label-hidden">
                                    <div className="field-items">
                                      <div className="field-item even">
                                        <a
                                          href="/jarabe-iniston/expectorante-descongestivo/"
                                          className="btn btn-blue"
                                        >
                                          Ver contenido
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <ul className="links inline">
                                  <li className="node-readmore first last">
                                    <a
                                      href="/destacado/alivio-de-los-mocos-y-la-congestion/"
                                      rel="tag"
                                      title="Alivio de los mocos y la congestión"
                                    >
                                      Leer más
                                      <span className="element-invisible">
                                        {" "}
                                        sobre Alivio de los mocos y la
                                        congestión
                                      </span>
                                    </a>
                                  </li>
                                </ul>{" "}
                              </div>
                            </div>
                          </article>
                        </div>
                        <div className="field-item odd">
                          <article className="node-101 node node-highlight node-teaser clearfix">
                            <header>
                              <h2 className="node__title node-title">
                                <a href="/la-tos/tipos-de-tos/con-mocos/">
                                  Olvídate de la tos con mocos
                                </a>
                              </h2>
                            </header>
                            <div className="node-content-wrapper">
                              <div className="node-content">
                                <div className="group-highlight-left field-group-div">
                                  <div className="field field-name-field-highlight-img field-type-image field-label-hidden">
                                    <div className="field-items">
                                      <div className="field-item even">
                                        <img
                                          src="/sites/default/files/styles/highlight/public/highlight/descubre-como-aliviar-tos-mocos.png?itok=SXYndvGv"
                                          width="302"
                                          height="157"
                                          alt="Descubre como aliviar tos mocos"
                                          title="Descubre como aliviar tos mocos"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="group-highlight-right field-group-div">
                                  <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                                    <div className="field-items">
                                      <div className="field-item even">
                                        <p>
                                          La tos nos ayuda a eliminar el exceso
                                          de mucosidad provocada por infección
                                          en las vías respiratorias. Descubre
                                          más sobre las causas, síntomas y
                                          tratamientos de la tos con mocos.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="field field-name-field-highlight-link field-type-link-field field-label-hidden">
                                    <div className="field-items">
                                      <div className="field-item even">
                                        <a
                                          href="/la-tos/tipos-de-tos/con-mocos/"
                                          className="btn btn-blue"
                                        >
                                          Ver contenido
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <ul className="links inline">
                                  <li className="node-readmore first last">
                                    <a
                                      href="/destacado/descubre-como-aliviar-tos-mocos/"
                                      rel="tag"
                                      title="Olvídate de la tos con mocos"
                                    >
                                      Leer más
                                      <span className="element-invisible">
                                        {" "}
                                        sobre Olvídate de la tos con mocos
                                      </span>
                                    </a>
                                  </li>
                                </ul>{" "}
                              </div>
                            </div>
                          </article>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </article>
            </div>
            <div id="navigation"></div>
          </div>
          <footer id="footer" className="region region-footer">
            <div
              id="block-menu-menu-menu-footer"
              className="block block-menu first odd"
              role="navigation"
            >
              <ul className="menu">
                <li className="menu__item is-leaf first leaf">
                  <a href="/contacto/" className="menu__link">
                    Contacto
                  </a>
                </li>
                <li className="menu__item is-leaf leaf">
                  <a href="/politica-de-privacidad/" className="menu__link">
                    política de privacidad
                  </a>
                </li>
                <li className="menu__item is-leaf leaf">
                  <a href="/politica-de-cookies/" className="menu__link">
                    política de cookies
                  </a>
                </li>
                <li className="menu__item is-leaf leaf">
                  <a href="/aviso-legal/" className="menu__link">
                    Aviso legal
                  </a>
                </li>
                <li className="menu__item is-leaf leaf">
                  <a href="/acceso-profesionales/" className="menu__link">
                    Acceso profesionales
                  </a>
                </li>
                <li className="menu__item is-leaf leaf">
                  <a href="/preguntas-frecuentes/" className="menu__link">
                    Faqs
                  </a>
                </li>
                <li className="menu__item is-leaf last leaf">
                  <a href="#" id="ot-sdk-btn" className="ot-sdk-show-settings" rel="nofollow">
                    Configuración de cookies
                  </a>
                </li>
              </ul>
            </div>
            <div id="block-block-1" className="block block-block last even">
              <p className="rtecenter">
                Publicada por Johnson &amp; Johnson S.A., único responsable de
                su contenido está dirigida a residentes en España.
                <br />
                ©&nbsp;Johnson &amp; Johnson S.A, 2015. Última actualización
                20/12/2014
              </p>
            </div>
          </footer>
        </div>
        <script src="/sites/default/files/js/js_pMpn-aGMsGKgMnAtmxLe-SZAZXUOnXxQqYAoiRN9DJI.js" />
        <script
          dangerouslySetInnerHTML={{
            __html: 'var eu_cookie_compliance_cookie_name = "";'
          }}
        />
        
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction read_cookie(k,r){return(r=RegExp('(^|; )'+encodeURIComponent(k)+'=([^;]*)').exec(document.cookie))?r[2]:null;}\nvar cookie_agreed = read_cookie('cookie-agreed');\nif (cookie_agreed == 2) {\n/* <!--[CDATA[ */\nvar google_conversion_id = 1004307202;\nvar google_custom_params = window.google_tag_params;\nvar google_remarketing_only = true;\n/* ]]--> */\n}\n"
          }}
        />
        <script
          type="text/javascript"
          src="//www.googleadservices.com/pagead/conversion.js"
        />
        <noscript
          dangerouslySetInnerHTML={{
            __html:
              '\n  <div style="display:inline">\n    <img height="1" width="1" style="border-style:none" alt="" src="//googleads.g.doubleclick.net/pagead/viewthroughconversion/1004307202/?value=0&amp;guid=ON&amp;script=0">\n  </div>\n'
          }}
        />
      </div>
    );
  }
}

export default Page;
